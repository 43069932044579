@import "./variables";

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1400px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $max: map-get($breakpoints, $name);
  @return if($max and $max > 0, $max - .02, null);
}

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

@function to-rgb($value) {
    @return red($value), green($value), blue($value);
}

//if you send 2, 'px' it will generate (0:0px, 1:1px, 2:2px)
@function generate-number-map($count, $unit: null) {
  $map: ();
  @for $i from 0 through $count {
    $value: if($unit == null or $unit == '', $i, $i + $unit);
    $map: map-merge($map, ($i: $value));
  }
  @return $map;
}

@mixin make-gutters {
  padding-right: calc($gutter-width / 2);
  padding-left: calc($gutter-width / 2);
}

//   $properties like (padding-left, padding-right) or (padding-right)
//   $value like 2rem
@mixin add-css-properties($properties, $value) {
  @each $property in $properties {
    #{$property}: #{$value} !important;
  }   
}

@mixin generate-responsive-utilities($breakpoint, $size, $classPrefix, $classPostfix, $properties, $value) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
  $postfix: if($classPostfix == '', "", "-#{$classPostfix}");

  .#{$classPrefix}#{$infix}#{$postfix} {
    @if $infix == "" {
      @include add-css-properties($properties, $value);        
    }
    @else {
      @media (min-width: #{$size}) {
        @include add-css-properties($properties, $value);        
      }
    }
  }
}

@mixin generate-unresponsive-utilities($classPrefix, $classPostfix, $properties, $value) {
  $postfix: if($classPostfix == '', "", "-#{$classPostfix}");

  .#{$classPrefix}#{$postfix} {
    @include add-css-properties($properties, $value);
  }
  
}

//$shorthand like mt or mx
//$properties like (margin-top) or (margin-top margin-bottom)
//$values like (  0: 0, 1: .25, 5:  3) so in the loop key will be 5 and value will be 3 at the last step
@mixin generate-utilities-map($shorthand, $properties, $values, $responsive: true) {
  @if $responsive {
    @each $breakpoint, $size in $grid-breakpoints {
      @each $key, $value in $values {
        @include generate-responsive-utilities($breakpoint, $size, $shorthand, $key, $properties, $value);
      }
    }
  }
  @else {
    @each $key, $value in $values {
      @include generate-unresponsive-utilities($shorthand, $key, $properties, $value);
    }
  }
}

//$shorthand like mt or mx
//$properties like (margin-top) or (margin-top margin-bottom)
//$values like (row column row-reverse column-reverse)
@mixin generate-utilities-array($shorthand, $properties, $values, $responsive: true) {
  @if $responsive {
    @each $breakpoint, $size in $grid-breakpoints {
      @each $value in $values {
        @include generate-responsive-utilities($breakpoint, $size, $shorthand, $value, $properties, $value);
      }
    }
  }
  @else {
    @each $value in $values {
      @include generate-unresponsive-utilities($shorthand, $value, $properties, $value);
    }
  }
}



