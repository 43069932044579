.tuula-menu {
    background-color: var(--primary-color-500) !important;
    border: solid;
    border-radius: var(--border-radius) !important;
    border-width: 1px;
    border-color: var(--dark-border);
    * {
        box-sizing: border-box;
    }
}

.tuula-menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    padding: 10px;

    border-bottom: solid 1px var(--primary-color-400) !important;

    cursor: pointer;

    i{
      display: inline-block;
      filter: invert(1);
    }


    &:hover:not(:disabled){
        background-color: var(--primary-color-400) !important;;
    }

    &:disabled,
    &[disabled="true"] {
        opacity: 0.5;
        pointer-events: none;
    }

    & .tuula-menu-item-text {
        display: inline-block;
        list-style-type: none;
        -webkit-font-smoothing: antialiased;
        white-space: normal;
        line-height: var(--mat-menu-item-label-text-line-height);
        letter-spacing: var(--mat-menu-item-label-text-tracking);
        font-family: var(--tuula-font-family);
        font-size: 12px;
        font-weight: 400;
        color: var(--white);
        display: inline-block;
    }

}