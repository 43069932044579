@use "sass:math";
@use './mixins' as *;


@mixin make-col($size) {
  flex: 0 0 math.percentage(math.div($size, $grid-columns));
  max-width: math.percentage(math.div($size, $grid-columns));
}

@mixin make-col-ready() {
  flex: 0 0 100%;
  max-width: 100%;
}

@mixin create-col-default-classes($infix,$breakpoint-max) {
  .col#{$infix} {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .col#{$infix}-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  @media (max-width: $breakpoint-max) {
    .col#{$infix} {
      @include make-col-ready();
    }
    .col#{$infix}-auto {
      @include make-col-ready();
    }
  }
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: calc(-1 * $gutter-width / 2);
  margin-left: calc(-1 * $gutter-width / 2);
}

@each $breakpoint, $size in $grid-breakpoints {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  @include create-col-default-classes($infix,$size);

  @for $i from 1 through $grid-columns {
    .col#{$infix}-#{$i} {
      @include make-col($i);
    }

    @media (max-width: $size) {
      .col#{$infix}-#{$i} {
        @include make-col-ready();
      }
    }
  }

} 

