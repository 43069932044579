
.btn {
    display: flex;
    align-items: center;
    justify-content: center;

    width: auto;
    max-width: 100%;
    margin: 10px 8px;
    gap: 8px;
    padding: 8px 16px;

    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);

    font-size: 14px;
    line-height: 20px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    word-wrap: break-word;
    white-space: nowrap;

    cursor: pointer;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    i {
        font-size: 1.5em;
    }
    
    &:disabled,
    &[disabled="true"] {
        opacity: 0.5;
        pointer-events: none;
    }
}

.btn-primary {
    @extend .btn;

    background-color: var(--primary-color-500);
    color: var(--white);
    border: 1px solid var(--primary-color-200);

    &:hover,
    &[aria-expanded='true'] {
        background-color: var(--primary-color-400);
    }

    &:focus {
        background-color: var(--primary-color-400);
        box-shadow: 0 0 0 0.2rem rgba(var(--primary-color-500), 0.25);
        outline: none;
    }

    &:active {
        background-color: var(--primary-color-600);
        border-color: var(--primary-color-600);
        box-shadow: none;
    }
}

.btn-secondary {
    @extend .btn;

    background-color: var(--primary-color-50);
    color: var(--primary-color-700);
    border: 1px solid var(--primary-color-200);

    &:hover,
    &[aria-expanded='true'] {
        background-color: var(--gray-color-100);
    }

    &:focus {
        background-color: var(--gray-color-100);
        box-shadow: 0 0 0 0.2rem rgba(var(--primary-color-50), 0.25);
        outline: none;
    }

    &:active {
        background-color: var(--primary-color-200);
        border-color: var(--primary-color-200);
        box-shadow: none;
    }
}

.btn-light {
    @extend .btn;

    background-color: var(--white);
    color: var(--gray-color-700);
    border: 1px solid var(--gray-color-300);

    &:hover,
    &[aria-expanded='true'] {
        background-color: var(--gray-color-100);
    }

    &:focus {
        background-color: var(--gray-color-100);
        box-shadow: 0 0 0 0.2rem rgba(var(--white), 0.25);
        outline: none;
    }

    &:active {
        background-color: var(--gray-color-200);
        border-color: var(--gray-color-200);
        box-shadow: none;
    }
}

.btn-lavender {
    @extend .btn;

    background-color: var(--pale-lavender-color-50);
    color: var(--pale-lavender-button-text-color);
    border: 1px solid var(--gray-color-300);

    &:hover,
    &[aria-expanded='true'] {
        background-color: var(--pale-lavender-color-200);
    }

    &:focus {
        background-color: var(--pale-lavender-color-200);
        box-shadow: 0 0 0 0.2rem rgba(var(--white), 0.25);
        outline: none;
    }

    &:active {
        background-color: var(--pale-lavender-color-300);
        border-color: var(--pale-lavender-color-300);
        box-shadow: none;
    }
}


.btn-square-icon {
    @extend .btn-light;

    padding: 8px; 
    span {
        display: none;

        &.mat-badge-active {
            display: inline-block;
        }
    }
}

.btn-round-icon-borderless {
    @extend .btn;
  
    color: var(--mat-icon-color);
    font-size: 16px;

    padding: 4px;
    margin: 0;
  
    box-shadow: none;
    border: none;
    background: none;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  
    i {
        font-size: 1em;
    }

    span {
        display: none;

        &.mat-badge-active {
            display: inline-block;
        }
    }

    &:hover:not(:disabled),
    &[aria-expanded="true"] {
      background-color: var(--gray-color-300);
      border-radius: 50%;
    }

    &:focus {
        background-color: var(--gray-color-300);
        border-radius: 50%;
    }

    &:active {
        background-color: var(--gray-color-400);
        border-radius: 50%;
    }
  }