@use './mixins' as *;

// Generate padding utilities
@include generate-utilities-map(p, (padding), $spacers);
@include generate-utilities-map(pt, (padding-top), $spacers);
@include generate-utilities-map(pe, (padding-right), $spacers);
@include generate-utilities-map(pb, (padding-bottom), $spacers);
@include generate-utilities-map(ps, (padding-left), $spacers);
@include generate-utilities-map(px, (padding-left, padding-right), $spacers);
@include generate-utilities-map(py, (padding-top, padding-bottom), $spacers);

// Generate margin utilities
@include generate-utilities-map(m, (margin), $spacers);
@include generate-utilities-map(mt, (margin-top), $spacers);
@include generate-utilities-map(me, (margin-right), $spacers);
@include generate-utilities-map(mb, (margin-bottom), $spacers);
@include generate-utilities-map(ms, (margin-left), $spacers);
@include generate-utilities-map(mx, (margin-left, margin-right), $spacers);
@include generate-utilities-map(my, (margin-top, margin-bottom), $spacers);


// Generate auto margin utilities
@include generate-utilities-map(m, (margin), (auto: auto));
@include generate-utilities-map(mt, (margin-top), (auto: auto));
@include generate-utilities-map(me, (margin-right), (auto: auto));
@include generate-utilities-map(mb, (margin-bottom), (auto: auto));
@include generate-utilities-map(ms, (margin-left), (auto: auto));
@include generate-utilities-map(mx, (margin-left, margin-right), (auto: auto));
@include generate-utilities-map(my, (margin-top, margin-bottom), (auto: auto));


// Generate gap utilities
@include generate-utilities-map(gap, (gap), $spacers);
@include generate-utilities-map(row-gap, (row-gap), $spacers);
@include generate-utilities-map(column-gap, (column-gap), $spacers);


// Generate order utilities
@include generate-utilities-map(order, (order), (
    first: -1,
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    last: 6,
  ));

// Generate flex utilities
@include generate-utilities-map(flex, flex, (fill: 1 1 auto)); // usage: flex-fill
@include generate-utilities-array(flex, flex-direction, (row column row-reverse column-reverse)); // usage: flex-row, flex-row-reverse
@include generate-utilities-map(flex, flex-grow, (grow-0: 0, grow-1: 1)); // usage: flex-grow-1
@include generate-utilities-map(flex, flex-shrink, (shrink-0: 0, shrink-1: 1)); // usage: flex-shrink-0
@include generate-utilities-array(flex, flex-wrap, (wrap nowrap wrap-reverse)); // usage: flex-wrap-reverse
@include generate-utilities-map(justify-content, justify-content, (start: flex-start, end: flex-end, center: center, between: space-between, around: space-around, evenly: space-evenly,)); // usage: justify-content-end
@include generate-utilities-map(align-items, align-items, (start: flex-start, end: flex-end, center: center, baseline: baseline,stretch: stretch,)); // usage: align-items-center
@include generate-utilities-map(align-content, align-content, (start: flex-start, end: flex-end, center: center, between: space-between, around: space-around, stretch: stretch,)); // usage: align-content-stretch
@include generate-utilities-map(align-self, align-self, (auto: auto, start: flex-start, end: flex-end, center: center, baseline: baseline,stretch: stretch,)); // usage: align-self-baseline


//Generate display utilities
@include generate-utilities-array(d, display, (inline inline-block block grid inline-grid table table-row table-cell flex inline-flex none)); // usage: d-inline-flex or d-lg-none

//Generate align utilities
@include generate-utilities-array(align, vertical-align, (baseline top middle bottom text-bottom text-top), false); // usage: align-baseline
@include generate-utilities-map(float, float, (start: left,  end: right, none: none)); // usage: float-start or float-md-start
@include generate-utilities-map(object-fit, object-fit, (contain: contain,  cover: cover, fill: fill, scale: scale-down, none: none,)); // usage: object-fit-contain


//Generate opacity utilities
@include generate-utilities-map(opacity, opacity, (0: 0,  25: .25, 50: .5, 75: .75, 100: 1,), false); // usage: opacity-50


//Generate overflow utilities
@include generate-utilities-array(overflow, overflow, (auto hidden visible scroll), false); // usage: overflow-scroll
@include generate-utilities-array(overflow-x, overflow-x, (auto hidden visible scroll), false); // usage: overflow-x-scroll
@include generate-utilities-array(overflow-y, overflow-y, (auto hidden visible scroll), false); // usage: overflow-y-scroll

//Generate position utilities
@include generate-utilities-array(position, position, (static relative absolute fixed sticky), false); // usage: position-absolute
@include generate-utilities-map(top, top, $position-values, false); // usage: top-0 or top-100 or top-50
@include generate-utilities-map(bottom, bottom, $position-values, false); // usage: bottom-0 or bottom-100 or bottom-50
@include generate-utilities-map(start, left, $position-values, false); // usage: start-0 or start-100 or start-50
@include generate-utilities-map(end, right, $position-values, false); // usage: end-0 or end-100 or end-50

//Generate translate-middle utilities
@include generate-utilities-map(translate-middle, translate-middle, (null: translate(-50%, -50%),x: translateX(-50%),y: translateY(-50%),), false); // usage: translate-middle-x, translate-middle-null

//Generate border-width utilities
$border-widths-map: generate-number-map(5, 'px');
@include generate-utilities-map(border, border-width, $border-widths-map, false); // usage: border-0, border-1, ..., border-5

//Generate width utilities
@include generate-utilities-map(w, width, (25: 25%,  50: 50%, 75: 75%, 100: 100%, auto:auto), false); // usage: w-50
@include generate-utilities-map(mw, max-width, (100: 100%), false); // usage: mw-100
@include generate-utilities-map(vw, width, (100: 100vw), false); // usage: vw-100
@include generate-utilities-map(min-vw, min-width, (100: 100vw), false); // usage: min-vw-100

//Generate height utilities
@include generate-utilities-map(h, height, (25: 25%,  50: 50%, 75: 75%, 100: 100%, auto:auto), false); // usage: h-50
@include generate-utilities-map(mh, max-height, (100: 100%), false); // usage: mh-100
@include generate-utilities-map(vh, height, (100: 100vh), false); // usage: vh-100
@include generate-utilities-map(min-vh, min-height, (100: 100vh), false); // usage: min-vh-100


//Generate text utilities
@include generate-utilities-map(text, text-align, (start: left,  end: right, center: center,), true); // usage: text-start
@include generate-utilities-array(text-decoration, text-decoration, (none underline line-through), false); // usage: text-decoration-underline
@include generate-utilities-array(text, text-transform, (lowercase uppercase capitalize), false); // usage: text-lowercase
@include generate-utilities-map(text, white-space, (wrap: normal,nowrap: nowrap), false); // usage: text-wrap
@include generate-utilities-map(text, (word-wrap word-break), (break: break-word), false); // usage: text-break
@include generate-utilities-map(text-opacity, text-opacity, (25: .25, 50: .5, 75: .75, 100: 1,), false); // usage: text-opacity-50

//Generate misc utilities
@include generate-utilities-array(user-select, user-select, (all auto none), false); // usage: user-select-all
@include generate-utilities-array(pe, pointer-events, (none auto), false); // usage: pe-none
@include generate-utilities-map(z, z-index, $zindex-levels, false); // usage: z-1




////////////////////////////////////ADDITONAL UTILITIES/////////////////////////////////////////////
.d-flex-row {
  display: flex;
  flex-direction: row;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

$pixel-spacers: generate-number-map(25, 'px');

@include generate-utilities-map(px-p, (padding), $pixel-spacers, false);
@include generate-utilities-map(px-pt, (padding-top), $pixel-spacers, false);
@include generate-utilities-map(px-pe, (padding-right), $pixel-spacers, false);
@include generate-utilities-map(px-pb, (padding-bottom), $pixel-spacers, false);
@include generate-utilities-map(px-ps, (padding-left), $pixel-spacers, false);
@include generate-utilities-map(px-px, (padding-left, padding-right), $pixel-spacers, false);
@include generate-utilities-map(px-py, (padding-top, padding-bottom), $pixel-spacers, false);

@include generate-utilities-map(px-m, (margin), $pixel-spacers, false);
@include generate-utilities-map(px-mt, (margin-top), $pixel-spacers, false);
@include generate-utilities-map(px-me, (margin-right), $pixel-spacers, false);
@include generate-utilities-map(px-mb, (margin-bottom), $pixel-spacers, false);
@include generate-utilities-map(px-ms, (margin-left), $pixel-spacers, false);
@include generate-utilities-map(px-mx, (margin-left, margin-right), $pixel-spacers, false);
@include generate-utilities-map(px-my, (margin-top, margin-bottom), $pixel-spacers, false);